angular.module('app')
.constant('AppRoutes',
{
  "base": window.location.origin + "/",

  "auth.login": "login",

  "user.account": "account/overview",
  "user.addresses": "account/manage-addresses",
  "user.preferences": "account/preferences",
  "user.update": "account/update-account",
  "user.password_change": "account/change-password",
  "user.tickets": "account/my-tickets",
  "user.ticket_details": "account/my-tickets/ticket-details?order_no={order_no}&perf_no={perf_no}",
  "user.manage_tickets": "account/my-tickets/manage-tickets?action={action}&order_no={order_no}&perf_no={perf_no}",
  "user.tickets_grouped": "account/my-tickets",
  "user.ticket_details_grouped": "account/my-tickets/ticket-details?perf_no={perf_no}",
  "user.manage_tickets_grouped": "account/my-tickets/manage-tickets?action={action}&perf_no={perf_no}",

  "orders": "account/orders-and-tickets",
  "orders.details": "account/orders-and-tickets/order-details?order_no={order_no}",
  "donation.gift_certificates": "account/gift-certificates",

  "search": "tickets/find?search=%7B\"eventT\":\"{event}\",\"timeT\":\"any%20time\",\"quantity\":{quantity},\"priceId\":3%7D",
  "tickets": "tickets",

  "promo": "promo?code={promoCode}",

  "booking.best_available": "booking/best-available?perf_no={perf_no}",
  "booking.best_available.season": "booking/best-available?prod_no={prod_no}",
  "booking.best_available.full": "booking/best-available?prod_no={prod_no}&perf_no={perf_no}",
  "booking.syos": "booking/syos?perf_no={perf_no}&facility_no={facility_no}",
  "booking.syos.flex": "booking/syos?perf_no={perf_no}&facility_no={facility_no}&add_on=false",
  "booking.syos.flex.addon": "booking/syos?perf_no={perf_no}&facility_no={facility_no}&add_on=true",
  "booking.syos.fixed": "booking/syos?pkg_no={pkg_no}&facility_no={facility_no}",
  "booking.basket": "booking/cart",
  "donation.standard": "booking/donations",
  "donation.gift_membership": "account/gift-certificates/purchase",

  "packages.renewal": "tessitura/package/season/{season_no}?renew={order_no}&packages={packages}",

  "booking.delivery": "booking/secure/delivery",
  "booking.checkout": "booking/secure/payment",
  "booking.process": "booking/secure/process",
  "booking.confirmation": "booking/secure/complete?order-no={orderNo}",

  "donation.upgrade_renew": "tessitura/fund/{fund_no}",

  "donation.campaign": "donation-one-off.html",

  "cms.image": "tessitura/image/{prod_no}",
  "cms.image2": "tessitura/image-v2/{prod_no}/{perf_no}",
  "cms.link": "tessitura/event/{prod_no}",
  "cms.digital_link": "tessitura/digital-event/{perf_no}",
  "cms.event": "tessitura/eventDetails/{prod_no}",
  "cms.video_event_data": "tessitura/video-event-details",
  "cms.digital_events": "tessitura/upcoming-digital-events",

  "yap.conference": "/account/student-matinee-application/educators-conference?order_no={orderNo}&perf_no={perfNo}&li_seq_no={liSeqNo}",
  "yap.survey": "/account/student-matinee-application/information-about-your-visit?order_no={orderNo}"
});
