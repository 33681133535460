/* global moment */

app.controller('ManagePaymentMethodsController', ['$scope', 'TessituraSDK', '$sce', '$window', 'appConfig', '$filter', function ($scope, TessituraSDK, $sce, $window, appConfig, $filter) {

    var arrayWrap = $filter('arrayWrap')

    $scope.loading = true;
    $scope.addNewCard = false;
    $scope.loadedIframe = false;
    $scope.previouslySavedCardsAvailable = false;
    $scope.user = {
        cards       : '',
        accountInfo : ''
    };

    $scope.init = function() {
        $scope.getUserCardDetails();

        TessituraSDK.GetAccountInfo().then(function (GetAccountInfoResponse) {
            $scope.user.accountInfo = GetAccountInfoResponse.data.result.GetAccountInfoResults;
        });
    }

    // Lists all saved cards for user
    $scope.getUserCardDetails = function() {
        $scope.disableLinks = false;

        var fontAwesomeIcons = {
            "Visah": 'cc-visa',
            "Master Card": 'cc-mastercard',
            "American Express": 'cc-amex',
            "Discover": 'cc-discover',
            "Diner's Club": 'cc-diners-club',
            "Default": "credit-card",
          };

        return TessituraSDK.GetUserCards().then(function (response) {
            $scope.previouslySavedCardsAvailable = true;

            var result = response.data.result;
            var cards = arrayWrap(result.ExecuteLocalProcedureResults.LocalProcedure);

            if (cards.length < 1) {
                $scope.previouslySavedCardsAvailable = false;
                return;
            }

            cards = cards.map(function(card) {
                if (card.description in fontAwesomeIcons) {
                    card.fa = fontAwesomeIcons[card.description];
                } else {
                    card.fa = fontAwesomeIcons['Default'];
                }
                card.card_expiry_dt = moment(card.card_expiry_dt, 'YYYY-MM-DDTHH:mm:ss').format('MM/YYYY');
                card.deleting = false;
                return card;
            });

            $scope.cardsDetails = cards;
        }).finally(function(){
            $scope.loading = false;
        });
    }

    $scope.deleteUserCardDetails = function(card) {
        if($scope.disableLinks) { return; }
        $scope.disableLinks = true;
        card.deleting = true;

        return TessituraSDK.DeleteUserCard({act_id: card.act_id}).then(function (response) {
            if(response.data.result) {
                $scope.getUserCardDetails();
            }
        }).catch(function() {
            window.alert("Error deleting card");
            $scope.disableLinks = false;
        });
    }

    // Load iframe to add card to vantiv
    window.frameLoad = function () {
        $scope.loadedIframe = true;
        $scope.$apply();
    };

    $scope.addCardDetails = function() {
        $scope.addNewCard = true;
        $scope.saveCard = true;
        $scope.loadedIframe = false;
        var GetElementTokensRequestParams = {
            method: 1,
            customerNo: $scope.user.accountInfo.AccountInformation.customer_no
        };

        return TessituraSDK.GetElementTokens(GetElementTokensRequestParams).then(function (GetElementTokensResponse) {
            if (!GetElementTokensResponse.data.result) {
                window.alert("An error occured, please try again");
            } else {
                $scope.iframe = $sce.trustAsResourceUrl(
                    appConfig.elementEndpoint + '?TransactionSetupID=' + GetElementTokensResponse.data.result  // Callback URL for iframe so response can be sent back from proxy
                );
            }
        }).catch(function (GetElementTokensError) {
            window.alert("An error occured, please try again");
        });
    }


    // Handle event when proxy returns a response.

    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
        var data = JSON.parse(event.data);

        if (!data.referrer || data.referrer !== "proxy") {
            return;
        }

        if (data.result) {
            $scope.getUserCardDetails();
        } else {
            window.alert("An error occured, please try again");
        }
    }

    $scope.init();

}]);