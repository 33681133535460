/* global moment */
/* global TessituraMerchantServices */
app.controller('TMSPaymentGatewayController', ['$q', '$scope', '$filter', '$window', '$sce', '$modal', 'appConfig', 'TessituraSDK', 'TessituraRESTSDK', 'Router', 'CacheStorage', 'Exchange', 'GoogleTagManager', 'Cart', 'AuthenticationService', 'proxyClient', '$compile', 'proxyEndpoint', function ($q, $scope, $filter, $window, $sce, $modal, appConfig, TessituraSDK, TessituraRESTSDK, Router, CacheStorage, Exchange, GoogleTagManager, Cart, AuthenticationService, proxyClient, $compile, proxyEndpoint) {
    var
        defaultAddressStreet1 = 'web added',
        paymentMethods,
        cartData,
        emailAddress,
        billingAddress,
        arrayWrap = $filter('arrayWrap'),
        unique = $filter('unique');

    GoogleTagManager.sendCheckoutStep(3);

    $scope = angular.extend($scope, {
        loading: 0,
        cartData: {},
        // paymentDetailsNeeded: true,
        reloadOrderSummary: function () {},
        // cards: [],
        // chosenCard: null,
        // isGuest: false,
        // disableSavedCard: false,
        // guestEmail: null,
        processingOrder: false,
        redeemAGiftCertificate: redeemAGiftCertificate,
        processOrder: processOrder,
        termsAccepted: true,
        // savedCard: false,
        // newCard: true,
        // iframe: null,
        // saveCard: false,
        // newCardName: null,
        noCardNeeded: false,
        hasValidAddress: false,
        loadingPaymentProcessor: false,
        processingError: null,
        // usingSpecialCard: false,
        showCovidTermsAgreementMessage: false,
        agreedToTerms: agreedToTerms,
    });

    // Start the loading icon
    $scope.loading++;

    // 1) Check we are following the exchange or allocation
    redirectIfRulesFailure()

    // 2) Make sure the user has logged in
    .then(AuthenticationService.requireLogin)

    // 3) Take user back to the cart page if session expired
    .then(redirectIfSessionExpired)

    // 4) Take the user back to the cart page if there is empty
    .then(redirectIfCartEmpty)

    // 5) Load the cart data and credit card icc type
    .then(reloadCartData)

    // 6) Check if this is an anonymous user
    .then(AuthenticationService.isUserRegistered)

    // 7) Check if address has been set
    .then(checkForValidAddress)

    // 8) Finally, extend the time by two minutes
    .then(extendSessionTime)

    // 9) Get email address (optionally)
    .then(getEmailAddress)

    // Everything is loaded!
    .then(function () {
        $scope.loading--;
    });

    /**
     * Checks if the user is following the exchange or allocation rules
     * Sends them to the cart if they arent where they will see an error message
     * @return {Promise}
     */
    function redirectIfRulesFailure() {
        return $q.all([
            Exchange.checkExchangeModeRules(), Cart.checkAllocation()
        ]).then(function (results) {
            if (results[0] == false || results[1].length) {
                $window.location = Router.getUrl('booking.basket');
                return $q.reject();
            }

            return $q.resolve();
        });
    }

    function redirectIfSessionExpired() {
        return TessituraSDK.GetCart(true).catch(function () {
            $window.location = Router.getUrl('booking.basket');
            return $q.reject();
        });
    }

    function redirectIfCartEmpty() {
        return Cart.isCartEmpty().then(function (result) {
            if (result == true) {
                $window.location = Router.getUrl('booking.basket');
                return $q.reject();
            }
        });
    }

    function reloadCartData() {
        return Cart.getCart(true).then(function (_cartData) {

            // Map variables
            cartData = _cartData;
            CacheStorage.set('cartData', _cartData);
            $scope.cartData = cartData;

            // Redirect to basket if cart is empty
            if (!(cartData.Order && (cartData.SubLineItem  || cartData.PackageSubLineItem  || cartData.Contribution || cartData.Payment))) {
                $window.location = Router.getUrl('booking.basket');
                return $q.defer().promise;
            }

            var ticketsInCovidTermsFacilities = cartData.SubLineItem.filter(function (item) {
                return appConfig.covid_terms_facilities.indexOf(parseInt(item.facility_no)) > -1;
            });

            if (ticketsInCovidTermsFacilities.length <= 0) {
                ticketsInCovidTermsFacilities = cartData.PackageSubLineItem.filter(function (item) {
                    return appConfig.covid_terms_facilities.indexOf(parseInt(item.facility_no)) > -1;
                });
            }

            if (document.querySelector('#covid-terms-message') && ticketsInCovidTermsFacilities.length > 0) {
                $scope.showCovidTermsAgreementMessage = true;
                $scope.termsAccepted = false;
                return showCovidPopup();
            }

            // Disable saved cards if this is an YAP application
            $scope.disableSavedCard = ((
                'notes' in cartData.Order &&
                angular.isString(cartData.Order.notes) &&
                cartData.Order.notes.toLowerCase().indexOf(appConfig.yapApplicationNote.toLowerCase()) !== -1
            ) || (
                'custom_6' in cartData.Order &&
                angular.isString(cartData.Order.custom_6) &&
                cartData.Order.custom_6.length
            ));
        }).then(function () {
            // Decide if we need to load element
            if (paymentDetailsNeeded()) {
                loadTMS();
            } else {
                noCardNeeded();
            }
        });
    }

    function checkForValidAddress(isUserRegistered) {
        if (!isUserRegistered) {
            return TessituraSDK.GetCart(true).then(function (response) {
                var
                    result = response.data.result,
                    cartResults = result.GetCartResults,
                    order = cartResults.Order;

                $scope.hasValidAddress = 'address_no' in order;
            });
        }

        return TessituraSDK.GetConstituentInfoEx().then(function (response) {
            var
                result = response.data.result,
                constituentInfo = result.GetConstituentInfoExResults,
                addresses = arrayWrap(constituentInfo.Addresses);

            addresses = $filter('filter')(addresses, function (address) {
                return address.street1 != defaultAddressStreet1 && address.primary_ind == 'Y' && address.inactive == 'N';
            });

            if (addresses.length) {
                billingAddress = addresses[0];
            }

            $scope.hasValidAddress = !!addresses.length;
        });
    }

    function extendSessionTime() {
        return TessituraSDK.ResetTicketExpiration({
            checkout: true
        });
    }

    function getEmailAddress() {
        if (parseInt(cartData.Order.shipping_method, 10) !== appConfig.digital_tickets_delivery_method) {
            return $q.resolve();
        }

        return AuthenticationService.getEmailAddress().then(function (email) {
            CacheStorage.set('emailAddress', email);
            emailAddress = email;
        });
    }

    function agreedToTerms(value) {
        CacheStorage.set('CovidTerms', value);
        $scope.termsAccepted = value;
    }

    /**
     * Sets up the UI for not needed a credit card
     */
    function noCardNeeded() {
        $scope.noCardNeeded = true;
    }

    function paymentDetailsNeeded() {
        return parseInt(cartData.Order.balance_to_charge) > 0;
    }

    function loadTMS() {
        $scope.loadingPaymentProcessor = true;

        var payload = null;
        var authorizeUrl = null;
        var finalizeUrl = null;

        return TessituraSDK.TMSPaymentComponent({amount: $scope.cartData.Order.amt_to_charge}).then(function (response) {
            if (!response.data.result) {
                return $q.reject('Sorry, we could not process your transaction.');
            }

            payload = response.data.result.Payload;
            authorizeUrl = response.data.result.AuthorizeUrl;
            finalizeUrl = response.data.result.FinalizeUrl;

            return getPaymentComponent(response.data.result.Url, response.data.result.Checksum);
        }).then(function () {
            var merch = new TessituraMerchantServices.PaymentComponent({
                payload: payload,
                endpoints: {
                    authorize: authorizeUrl,
                    finalize: finalizeUrl,
                },
                locale: 'en-US',
                countryCode: 'US',
                enableStoredCards: true,
                showAddress: 'none',
                billingAddress: {
                    street: billingAddress.street1,
                    city: billingAddress.city,
                    stateOrProvince: billingAddress.state,
                    postalCode: billingAddress.postal_code,
                    country: billingAddress.country,
                }
            });

            merch.mount('#payment-container');

            $scope.loadingPaymentProcessor = false;
        }).catch(function () {
            $scope.processingError = 'Sorry, we cannot process your payments right now. Please try again or contact Audience Services at 213.628.2772 if the problem persists.';
        });
    }

    var tmsCache = null;

    function getPaymentComponent(url, checksum) {
        if (tmsCache) {
            return tmsCache;
        }

        var deferred = $q.defer();

        var script = document.createElement('script');

        script.onload = function () {
            deferred.resolve(true);
        }

        script.src = url;
        script.integrity = checksum;
        script.crossOrigin = 'anonymous';

        document.head.appendChild(script);

        tmsCache = deferred.promise;

        return tmsCache;
    }

    /**
     * Opens the redeem a gift certificate popup
     * @return {[type]} [description]
     */
    function redeemAGiftCertificate() {
        var modalInstance = $modal.open({
            backdrop: 'static',
            windowClass: 'donation-popup-modal',
            templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'redeem-a-gift-certificate.html'),
            controller: 'RedeemGiftCertificate',
            resolve: {
                orderAmount: function () {
                    return cartData.Order.balance_to_charge;
                }
            }
        });

        modalInstance.result.then(function () {
            reloadCartData();
            $scope.reloadOrderSummary();
        });
    }

    /**
     * Completes the checkout process where the user
     * has no balance in their cart
     * @return {[type]} [description]
     */
    function processOrder() {
        $scope.processingOrder = true;
        $scope.processingError = null;

        TessituraSDK.Checkout().then(function () {
            $window.location = Router.getUrl('booking.confirmation', {
                orderNo: cartData.Order.order_no
            });
        }).catch(function (error) {
            // If there is an error, show it to the user
            // and disable the loading icon
            $scope.processingError = error && error.data ? error.data.error : error;

            $scope.processingOrder = false;
        });
    }

    function showCovidPopup() {
        var modalInstance = $modal.open({
            backdrop: 'static',
            windowClass: 'covid-terms-popup',
            controller: 'CovidTermsController',
            templateUrl: $sce.trustAsResourceUrl(appConfig.templateBaseUrl + 'covid-terms.html'),
            resolve: {
                terms: function () {
                    var termsMsg = document.querySelector('#covid-terms-message').innerText;
                    return $sce.trustAsHtml(termsMsg);
                },
                agreedToTerms: function() {
                    return function (state) {
                        agreedToTerms(state);
                        modalInstance.close();
                    }
                }
            }
        });
    }
}]);
