/* global moment */
/* global TessituraMerchantServices */
app.controller('TMSProcessPayment', ['$q', '$scope', '$filter', '$window', '$sce', '$modal', 'appConfig', 'TessituraSDK', 'TessituraRESTSDK', 'Router', 'CacheStorage', 'Exchange', 'GoogleTagManager', 'Cart', 'AuthenticationService', 'proxyClient', '$compile', 'proxyEndpoint', 'GetParameters', function ($q, $scope, $filter, $window, $sce, $modal, appConfig, TessituraSDK, TessituraRESTSDK, Router, CacheStorage, Exchange, GoogleTagManager, Cart, AuthenticationService, proxyClient, $compile, proxyEndpoint, GetParameters) {
    $scope = angular.extend($scope, {
        loading: true,
        error: null
    });

    var redirectResult = GetParameters['redirectResult'];

    TessituraSDK.TMSReturnRedirect({ redirectResult: redirectResult }).then(function (response) {
        if (response.result === true) {
            // On successful completion forward the user to the confirm page
            $window.location = Router.getUrl('booking.confirmation', {
                orderNo: GetParameters['orderNo']
            });
        }

        return $q.reject();
    }).catch(function () {
        $scope.error = 'Sorry, we could not process your transaction. You will now be taken back to the payment page.';

        setTimeout(function () {
            $window.location = Router.getUrl('booking.checkout');
        }, 2500);
    });
}]);
